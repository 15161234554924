import React from 'react';
import { Line } from "react-chartjs-2";


import classNames from "classnames";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,

} from "reactstrap";



function LineGraph({lg, data, title, subtitle}) {
  const [yearButton, setYearButton] = React.useState("data3");

  const [year, setYear] = React.useState(0);
  React.useEffect(() => {
    setYear(new Date().getFullYear()) 
  },[])
  return (
    <Row>
          <Col xs={lg}>
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">{subtitle}</h5>
                    <CardTitle tag="h2">{title}</CardTitle>
                  </Col>
                  <Col sm="6">
                     <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: yearButton === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setYearButton("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          {year - 2 }
                        </span>
                        <span className="d-block d-sm-none">
                        {year - 2 }
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: yearButton === "data2",
                        })}
                        onClick={() => setYearButton("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          {year - 1}
                        </span>
                        <span className="d-block d-sm-none">
                        {year - 1}
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: yearButton === "data3",
                        })}
                        onClick={() => setYearButton("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          {year}
                        </span>
                        <span className="d-block d-sm-none">
                          {year}
                        </span>
                      </Button>
                    </ButtonGroup> 
                  </Col>
                </Row>
                
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={data[yearButton]}
                    options={data.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
  )
}

export default LineGraph;