import api from '../services/api'

export default async function ChurnConfig(){
  const metric = "churn";
  const Year  = new Date().getFullYear();

  const {data} = await api.get(`reports/metrics?token=${process.env.REACT_APP_TOKEN}&metric=${metric}&year=${Year}`);

  return  {
    data1: (canvas) => {
      let ctx = canvas.getContext("2d");
  
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
  
      gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
      gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
      gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors
  
      return {
        labels: ["Upgrade", "Downgrade", "Stables"],
        datasets: [
          {
            label: "Percent",
            fill: true,
            backgroundColor: gradientStroke,
            hoverBackgroundColor: gradientStroke,
            borderColor: 'red',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: [ data[Year - 2].planUpgradesRatio, data[Year - 2].planDowngradeRatio, data[Year - 2].planStablesRatio,],
          },
        ],
      };
    },
    data2: (canvas) => {
      let ctx = canvas.getContext("2d");
  
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
  
      gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
      gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
      gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors
  
      return {
        labels: ["Upgrade", "Downgrade", "Stables"],
        datasets: [
          {
            label: "Percent",
            fill: true,
            backgroundColor: gradientStroke,
            hoverBackgroundColor: gradientStroke,
            borderColor: 'red',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: [ data[Year - 1].planUpgradesRatio, data[Year - 1].planDowngradeRatio, data[Year - 1].planStablesRatio,],
          },
        ],
      };
    },
    data3: (canvas) => {
      let ctx = canvas.getContext("2d");
  
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
  
      gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
      gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
      gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors
  
      return {
        labels: ["Upgrade", "Downgrade", "Stables"],
        datasets: [
          {
            label: "Percent",
            fill: true,
            backgroundColor: gradientStroke,
            hoverBackgroundColor: gradientStroke,
            borderColor: 'red',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: [ data[Year].planUpgradesRatio, data[Year].planDowngradeRatio, data[Year].planStablesRatio,],
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 60,
              suggestedMax: 120,
              padding: 20,
              fontColor: "#9e9e9e",
              max:100,
              min:0
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(225,78,202,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e",
            },
          },
        ],
      },
    },
  };
}