import React from 'react';
import classNames from "classnames";

import {  Bar } from "react-chartjs-2";


// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Col,
 
} from "reactstrap";

function ColumnGraph({lg, data, title, subtitle}) {
  const [avarageTicket, setAvarageTicket] = React.useState("data1");

  const [year, setYear] = React.useState(0);

 React.useEffect(() => {
    setYear(new Date().getFullYear()) 
  },[])
  return (
    <Col lg={lg}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">{subtitle}</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-delivery-fast text-primary" />{" "}
                  {title}
                  <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                    <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: avarageTicket === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setAvarageTicket("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          {year - 2 }
                        </span>
                        <span className="d-block d-sm-none">
                        {year - 2 }
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: avarageTicket === "data2",
                        })}
                        onClick={() => setAvarageTicket("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          {year - 1}
                        </span>
                        <span className="d-block d-sm-none">
                          {year - 1}
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: avarageTicket === "data3",
                        })}
                        onClick={() => setAvarageTicket("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          {year}
                        </span>
                        <span className="d-block d-sm-none">
                        {year}
                        </span>
                      </Button>
                    </ButtonGroup>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Bar
                    data={data[avarageTicket]}
                    options={data.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
  )
}

export default ColumnGraph;