/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import {useHistory} from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';


import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";


function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const {auth, setAuth} = useContext(AuthContext);
  const {push } = useHistory();

  

  function handleLogin() {
    if(email === process.env.REACT_APP_EMAIL && password === process.env.REACT_APP_PASSWORD ){
      setAuth(true);
      sessionStorage.setItem('Auth', process.env.REACT_APP_AUTH);
      push('/admin/metrics');
    }else{
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 4000);
    }
  }
  useEffect(()=>{
    if(sessionStorage.getItem('Auth') === process.env.REACT_APP_AUTH){
      setAuth(true);
      push('/admin/metrics');
    }
  },[])

  return (
    <>
      <div className="content">
        <Snackbar open={open} message="Combinação de email e senha invalido" />
        <Row style={{justifyContent: 'center'}}>
          <Col md="4" style={{marginTop:"15px"}}>
            <Card className="card-user" style={{padding: '5px', height: '250px'}}>
              <Row style={{justifyContent: 'center', alignItems: "center", height: '100%'}}>
                <Col md="10">
                  <FormGroup style={{marginTop: '8px'}}>
                    <label htmlFor="exampleInputEmail1">
                      Email
                    </label>
                    <Input onChange={(e) => {
                      setEmail(e.target.value)
                    }}  type="email" />
                  </FormGroup>
                  </Col>
                  <Col md="10">
                  <FormGroup>
                    <label>
                      Senha
                    </label>
                    <Input  onChange={(e) => {
                      setPassword(e.target.value)}} type="password" />
                  </FormGroup>
                  </Col>
                  <Col md="4">
                  <Button className="btn-fill" color="default" type="button" onClick={handleLogin}>
                    Logar
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Login;
