import axios from 'axios'

const BaseURL =
  process.env.REACT_APP_PRODUCTION !== 'TRUE'
    ? process.env.REACT_APP_QA_URL
    : process.env.REACT_APP_PRODUCTION_URL;

const api = axios.create({
  baseURL: BaseURL,
}) 

export default api