
import React, { useEffect } from "react";

import { Line } from "react-chartjs-2";


import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample4,
  Upsell,
  RegisteredUsers,
  FreeUsers,
  Access,
} from "variables/charts.js";
import AverageTicketData from "../config/AverageTicket"
import ChurnData from "../config/Churn"
import UpSellData from "../config/Upsell"
import AccessData from "../config/AccessVolume"

import ArpaData from "../config/Arpa"
import MrrData from "../config/Mrr"
import LtvData from "../config/Ltv"
import FreeUsersVolumeData from "../config/FreeUsersVolume"
import RegisteredUsersVolumeData from "../config/RegisteredUsersVolume"
import ColumnGraph from '../components/ColumnGraph'
import LineGraph from '../components/LineGraph'
import Loading from '../components/Loading'

function Dashboard(props) {
  const [averageTicketConfig, setAverageTicketConfig] = React.useState({});
  const [churnConfig, setChurnConfig] = React.useState({});
  const [upsellConfig, setUpsellConfig] = React.useState({});
  const [accessConfig, setAccessConfig] = React.useState({});
  const [ltvConfig, setLtvConfig] = React.useState({});
  const [freeUsersConfig, setFreeUsersConfig] = React.useState({});
  const [registeredUsersConfig, setRegisteredUsersConfig] = React.useState({});
  const [arpaConfig, setArpaConfig] = React.useState({});
  const [mrrConfig, setMrrConfig] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  
  // const {token} = useParams()
  // const token = (props.match.params.token)
  // console.log(token)

  async function load() {
    await AverageTicketData().then(res => {setAverageTicketConfig(res)})
    await ChurnData().then(res => {setChurnConfig(res)})
    await UpSellData().then(res => {setUpsellConfig(res)})
    await AccessData().then(res => {setAccessConfig(res)})
    await FreeUsersVolumeData().then(res => {setFreeUsersConfig(res)})
    await RegisteredUsersVolumeData().then(res => {setRegisteredUsersConfig(res)})
    await ArpaData().then(res => {setArpaConfig(res)})
    await MrrData().then(res => {setMrrConfig(res)})
    await LtvData().then(res => {setLtvConfig(res)})
  }
  
  useEffect(() => {
    load().then(()=> setIsLoading(false))
   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
   <>{
     isLoading ? (<Loading />) : ( <>
      <div className="content">
        
        <LineGraph lg={12} title="Arpa" subtitle="Average Revenue per Account" data={arpaConfig}/>
        <LineGraph lg={12} title="Mrr" subtitle="Recurring monthly revenue" data={mrrConfig}/>
        <LineGraph lg={12} title="LTV" subtitle="Lifetime value" data={ltvConfig}/>
        <LineGraph lg={12} title="Access Volume" subtitle="Access Volume" data={accessConfig}/>
        <LineGraph lg={12} title="Upsell" subtitle="Upsell" data={upsellConfig}/>
        <LineGraph lg={12} title="Registered users volume" subtitle="Registered users volume" data={registeredUsersConfig}/>
        <LineGraph lg={12} title="Free Users Volume" subtitle="Free Users Volume" data={freeUsersConfig}/>
        <LineGraph lg={12} title="Ticket" subtitle="Average Ticket" data={averageTicketConfig}/>
          <ColumnGraph lg={12} title="Plans Exchanges" subtitle="Churn" data={churnConfig}/>
      </div>
    </>)
   }</>
  );
}

export default Dashboard;
